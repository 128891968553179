import React from "react";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import SEO from "../../components/Seo";
import PageHero from "../../components/PageHero";
import { graphql, PageProps } from "gatsby";
import {
  ImageInterface,
  LandingSectionsInterface,
  LocalizedContextInterface,
  PortableText,
  MultiLineHeadline
} from "../../types/SanityTypes";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import BlockRenderer from "../../components/BlockRenderer";
import StructuredData from "../../components/StructuredData";
import PageModal, { PageModalInterface, Seo } from "../../components/PageModal";
import { LocalisedPageContext } from "../../types/PageTypes";

export type ProductLandingPageQueryProps = {
  productLandingPage: {
    seo: Seo;
    slug: {
      current: string;
    };
    heading: string;
    headline: MultiLineHeadline;
    _rawIntroduction: PortableText;
    _rawHeroImage: ImageInterface;
    pageModal?: PageModalInterface;
    landingSections: LandingSectionsInterface[];
    // For Preview
    introduction?: PortableText;
    heroImage?: ImageInterface;
  };
} & LocalizedContextInterface;

type ProductLandingPageContext = LocalisedPageContext;

export const data = graphql`
  query productLandingPage($_id: String, $language: String, $market: String) {
    productLandingPage: sanityProductLandingPage(_id: { eq: $_id }) {
      ...ProductLandingPageFieldsFull
    }
    ...LocalizedContext
  }
`;

const ProductLandingPage = (props: PageProps<ProductLandingPageQueryProps, ProductLandingPageContext>) => {
  const language = props.pageContext.language;
  const { htmlLang } = useSiteMetadata(language);
  const gradientProps = {
    gradientDegrees: "75",
    primaryGradientColor: "var(--blue-color)",
    secondaryGradientColor: "var(--blue-color)"
  };
  const productPage = props.data.productLandingPage;
  const pageTitle =
    productPage.seo?.metaTitle || productPage.headline.primaryText + " " + productPage.headline.secondaryText;
  const pageDescription = productPage.seo?.metaDescription || productPage._rawIntroduction[0].children[0].text;

  // This is to make sure banner works of internationalisation
  const lineOne = productPage.headline.primaryText + " ";
  const lineTwo = productPage.headline.secondaryText === null ? " " : productPage.headline.secondaryText + " ";
  const lineThree = productPage.headline.tertiaryText === null ? " " : productPage.headline.tertiaryText + " ";

  return (
    <Layout localizedContext={{ ...props.pageContext, ...props.data }} pageName={pageTitle} pageType={"products"}>
      <StructuredData
        type={"WebPage"}
        name={pageTitle}
        description={pageDescription}
        data={productPage._rawHeroImage}
        customData={productPage.seo.jsonld}
        language={htmlLang}
        slug={`/${productPage.heading}/`}
      />
      <SEO
        title={pageTitle}
        description={pageDescription}
        imageUrl={productPage._rawHeroImage?.asset?.url}
        imageAlt={productPage._rawHeroImage?.alt}
      />
      <div className="page_products">
        <Container fluid data-testid="product-page">
          <PageHero
            lineOne={lineOne}
            lineTwo={lineTwo}
            lineThree={lineThree}
            image={productPage._rawHeroImage}
            text={productPage._rawIntroduction}
            textPosition="align-self-start"
            gradientProps={gradientProps}
            themeColor={"var(--orange-bright-color)"}
            titlePosition="middle"
            gradientBackground={true}
          />
          {productPage.landingSections &&
            productPage.landingSections.map((section, index) => (
              <BlockRenderer key={"landingSection" + index} section={section} />
            ))}
        </Container>
      </div>
      {productPage.pageModal && <PageModal data={productPage.pageModal as unknown as PageModalInterface["data"]} />}
    </Layout>
  );
};

export default ProductLandingPage;
